

let btnGraphic1 = document.querySelector("#btn-graphic-1");
let btnGraphic2 = document.querySelector("#btn-graphic-2");
let graphic1 = document.querySelector("#graphic-1");
let graphic2 = document.querySelector("#graphic-2");



let eco1 = document.querySelector("#eco-1");
let eco2 = document.querySelector("#eco-2");
let eco3 = document.querySelector("#eco-3");
let eco4 = document.querySelector("#eco-4");
let eco5 = document.querySelector("#eco-5");

let ecoBtn1 = document.querySelector("#eco-btn-1");
let ecoBtn2 = document.querySelector("#eco-btn-2");
let ecoBtn3 = document.querySelector("#eco-btn-3");
let ecoBtn4 = document.querySelector("#eco-btn-4");
let ecoBtn5 = document.querySelector("#eco-btn-5");


ecoBtn1.onclick = function () {
    
    eco1.style.display = "none";
    eco3.style.display = "none";
    eco4.style.display = "none";
    eco5.style.display = "none";

    eco2.style.display = "grid";
    return false;
}

ecoBtn2.onclick = function () {
    
    eco2.style.display = "none";
    eco1.style.display = "none";
    eco4.style.display = "none";
    eco5.style.display = "none";

    eco3.style.display = "grid";
    return false;
}

ecoBtn3.onclick = function () {    
    eco3.style.display = "none";
    eco2.style.display = "none";
    eco1.style.display = "none";
    eco5.style.display = "none";

    eco4.style.display = "grid";
    return false;
}

ecoBtn4.onclick = function () {
    
    eco4.style.display = "none";
    eco3.style.display = "none";
    eco2.style.display = "none";
    eco1.style.display = "none";

    eco5.style.display = "grid";
    return false;
}

ecoBtn5.onclick = function () {
   
    eco5.style.display = "none";
    eco4.style.display = "none";
    eco3.style.display = "none";
    eco2.style.display = "none";

    eco1.style.display = "grid";
    return false;
}


let ecoNum11 = document.querySelector("#eco-num1--1");
let ecoNum21 = document.querySelector("#eco-num2--1");
let ecoNum31 = document.querySelector("#eco-num3--1");
let ecoNum41 = document.querySelector("#eco-num4--1");
let ecoNum51 = document.querySelector("#eco-num5--1");

let ecoNum12 = document.querySelector("#eco-num1--2");
let ecoNum22 = document.querySelector("#eco-num2--2");
let ecoNum32 = document.querySelector("#eco-num3--2");
let ecoNum42 = document.querySelector("#eco-num4--2");
let ecoNum52 = document.querySelector("#eco-num5--2");

let ecoNum13 = document.querySelector("#eco-num1--3");
let ecoNum23 = document.querySelector("#eco-num2--3");
let ecoNum33 = document.querySelector("#eco-num3--3");
let ecoNum43 = document.querySelector("#eco-num4--3");
let ecoNum53 = document.querySelector("#eco-num5--3");

let ecoNum14 = document.querySelector("#eco-num1--4");
let ecoNum24 = document.querySelector("#eco-num2--4");
let ecoNum34 = document.querySelector("#eco-num3--4");
let ecoNum44 = document.querySelector("#eco-num4--4");
let ecoNum54 = document.querySelector("#eco-num5--4");

let ecoNum15 = document.querySelector("#eco-num1--5");
let ecoNum25 = document.querySelector("#eco-num2--5");
let ecoNum35 = document.querySelector("#eco-num3--5");
let ecoNum45 = document.querySelector("#eco-num4--5");
let ecoNum55 = document.querySelector("#eco-num5--5");

ecoNum11.onclick = function () {
    eco5.style.display = "none";
    eco4.style.display = "none";
    eco3.style.display = "none";
    eco2.style.display = "none";

    eco1.style.display = "grid";
    return false;
}
ecoNum12.onclick = function () {
    eco5.style.display = "none";
    eco4.style.display = "none";
    eco3.style.display = "none";
    eco2.style.display = "none";

    eco1.style.display = "grid";
    return false;
}
ecoNum13.onclick = function () {
    eco5.style.display = "none";
    eco4.style.display = "none";
    eco3.style.display = "none";
    eco2.style.display = "none";

    eco1.style.display = "grid";
    return false;
}
ecoNum14.onclick = function () {
    eco5.style.display = "none";
    eco4.style.display = "none";
    eco3.style.display = "none";
    eco2.style.display = "none";

    eco1.style.display = "grid";
    return false;
}
ecoNum15.onclick = function () {
    eco5.style.display = "none";
    eco4.style.display = "none";
    eco3.style.display = "none";
    eco2.style.display = "none";

    eco1.style.display = "grid";
    return false;
}

ecoNum21.onclick = function () {
    eco1.style.display = "none";
    eco3.style.display = "none";
    eco4.style.display = "none";
    eco5.style.display = "none";

    eco2.style.display = "grid";
    return false;
}
ecoNum22.onclick = function () {
    eco1.style.display = "none";
    eco3.style.display = "none";
    eco4.style.display = "none";
    eco5.style.display = "none";

    eco2.style.display = "grid";
    return false;
}
ecoNum23.onclick = function () {
    eco1.style.display = "none";
    eco3.style.display = "none";
    eco4.style.display = "none";
    eco5.style.display = "none";

    eco2.style.display = "grid";
    return false;
}
ecoNum24.onclick = function () {
    eco1.style.display = "none";
    eco3.style.display = "none";
    eco4.style.display = "none";
    eco5.style.display = "none";

    eco2.style.display = "grid";
    return false;
}
ecoNum25.onclick = function () {
    eco1.style.display = "none";
    eco3.style.display = "none";
    eco4.style.display = "none";
    eco5.style.display = "none";

    eco2.style.display = "grid";
    return false;
}

ecoNum31.onclick = function () {
    eco2.style.display = "none";
    eco1.style.display = "none";
    eco4.style.display = "none";
    eco5.style.display = "none";

    eco3.style.display = "grid";
    return false;
}
ecoNum32.onclick = function () {
    eco2.style.display = "none";
    eco1.style.display = "none";
    eco4.style.display = "none";
    eco5.style.display = "none";

    eco3.style.display = "grid";
    return false;
}
ecoNum33.onclick = function () {
    eco2.style.display = "none";
    eco1.style.display = "none";
    eco4.style.display = "none";
    eco5.style.display = "none";

    eco3.style.display = "grid";
    return false;
}
ecoNum34.onclick = function () {
    eco2.style.display = "none";
    eco1.style.display = "none";
    eco4.style.display = "none";
    eco5.style.display = "none";

    eco3.style.display = "grid";
    return false;
}
ecoNum35.onclick = function () {
    eco2.style.display = "none";
    eco1.style.display = "none";
    eco4.style.display = "none";
    eco5.style.display = "none";

    eco3.style.display = "grid";
    return false;
}

ecoNum41.onclick = function () {
    eco3.style.display = "none";
    eco2.style.display = "none";
    eco1.style.display = "none";
    eco5.style.display = "none";

    eco4.style.display = "grid";
    return false;
}
ecoNum42.onclick = function () {
    eco3.style.display = "none";
    eco2.style.display = "none";
    eco1.style.display = "none";
    eco5.style.display = "none";

    eco4.style.display = "grid";
    return false;
}
ecoNum43.onclick = function () {
    eco3.style.display = "none";
    eco2.style.display = "none";
    eco1.style.display = "none";
    eco5.style.display = "none";

    eco4.style.display = "grid";
    return false;
}
ecoNum44.onclick = function () {
    eco3.style.display = "none";
    eco2.style.display = "none";
    eco1.style.display = "none";
    eco5.style.display = "none";

    eco4.style.display = "grid";
    return false;
}
ecoNum45.onclick = function () {
    eco3.style.display = "none";
    eco2.style.display = "none";
    eco1.style.display = "none";
    eco5.style.display = "none";

    eco4.style.display = "grid";
    return false;
}

ecoNum51.onclick = function () {
    eco4.style.display = "none";
    eco3.style.display = "none";
    eco2.style.display = "none";
    eco1.style.display = "none";

    eco5.style.display = "grid";
    return false;
}
ecoNum52.onclick = function () {
    eco4.style.display = "none";
    eco3.style.display = "none";
    eco2.style.display = "none";
    eco1.style.display = "none";

    eco5.style.display = "grid";
    return false;
}
ecoNum53.onclick = function () {
    eco4.style.display = "none";
    eco3.style.display = "none";
    eco2.style.display = "none";
    eco1.style.display = "none";

    eco5.style.display = "grid";
    return false;
}
ecoNum54.onclick = function () {
    eco4.style.display = "none";
    eco3.style.display = "none";
    eco2.style.display = "none";
    eco1.style.display = "none";

    eco5.style.display = "grid";
    return false;
}
ecoNum55.onclick = function () {
    eco4.style.display = "none";
    eco3.style.display = "none";
    eco2.style.display = "none";
    eco1.style.display = "none";

    eco5.style.display = "grid";
    return false;
}





